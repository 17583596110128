import upperCase from 'lodash/fp/upperCase';

import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Button, Typography } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries } from '@packages/hero-theme/utils';

const SideBarWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ItemsWrapper = styled.div`
  position: absolute;
  height: 100%;
  background-color: black;

  a {
    text-decoration: none;
  }
`;

const MenuIcon = styled(Button.Icon)`
  display: none !important;

  ${mediaMaxQueries.lg} {
    display: block !important;
  }
`;

type navBarItem = {
  id: string;
  name: string;
  href: string;
};

interface SideBarProp {
  navBarItems: navBarItem[];
}

const SideBar = ({ navBarItems }: SideBarProp) => {
  const router = useRouter();
  const [openSideBar, setOpenSideBar] = useState(false);

  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: router?.query?.mode,
      }),
    [router?.query?.mode]
  );

  return (
    <>
      {openSideBar && (
        <SideBarWrapper>
          <Overlay onClick={() => setOpenSideBar(false)} />

          <ItemsWrapper>
            {navBarItems.map(({ id, name, href }) => (
              <Link href={normalizeHref(href)} passHref key={id}>
                <a>
                  <Typography.Text
                    fontSize={14}
                    fontWeight="semi-bold"
                    style={{ color: 'white', padding: '30px' }}
                  >
                    {upperCase(name)}
                  </Typography.Text>
                </a>
              </Link>
            ))}
          </ItemsWrapper>
        </SideBarWrapper>
      )}

      <MenuIcon
        data-test-id="menu-icon"
        icon="menu"
        size="large"
        sx={{ mr: 'medium' }}
        onClick={() => setOpenSideBar(true)}
      />
    </>
  );
};

export default SideBar;
