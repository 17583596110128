import { useTheme } from 'styled-components';

import { Button, Typography } from '@hero-design/react';

import { MappedMenuItem } from './types';

type MenuItemLevel1Props = {
  selectedLevel1: string | null;
  item: MappedMenuItem;
  onClose: () => void;
  onOpen: (item: string) => void;
};

const MenuItemLevel1 = ({
  selectedLevel1,
  item,
  onClose,
  onOpen,
}: MenuItemLevel1Props) => {
  const theme = useTheme();

  const onClick = () => {
    if (selectedLevel1 === item.id) {
      onClose();
    } else {
      onOpen(item.id);
    }
  };

  const isItemSelected = selectedLevel1 === item.id;

  return (
    <Button
      key={item.id}
      onClick={onClick}
      text={
        <Typography.Text
          fontWeight="semi-bold"
          intent={isItemSelected ? 'white' : undefined}
          sx={{ mt: `-${theme.space.xsmall}px` }}
        >
          {item.title}
        </Typography.Text>
      }
      variant={isItemSelected ? 'filled' : 'text'}
      sx={{
        ml: isItemSelected ? 'xxxlarge' : 'xlarge',
        mr: isItemSelected ? 'xxxlarge' : 'xlarge',
      }}
    />
  );
};

export default MenuItemLevel1;
