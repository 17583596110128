import isNil from 'lodash/fp/isNil';

import React, { useState } from 'react';
import { useSession } from 'next-auth/react';

import { Banner, Box, Button, Typography } from '@hero-design/react';

import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import { addNotification } from '@shared/utils';
import {
  TRACT_ATS_RESEND_BUTTON_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import useResendVerificationEmail from 'src/modules/User/hooks/useResendVerificationEmail';

const EmailVerificationBanner = () => {
  const { permissionsData } = useFetchPermissions();
  const isLocked =
    !!permissionsData?.data?.show_unverified_locked_user_restriction_screen;

  const { track } = useMixpanelTracking();

  const { status } = useSession();

  const isAuthenticated = status === 'authenticated';

  const session = useSession();
  const email = session?.data?.user?.email;

  const { resendVerififcationEmail, isResendingVerificationEmail } =
    useResendVerificationEmail({
      onCompleted: () => {
        addNotification({
          intent: 'success',
          content: 'Email sent successfully, please check your inbox.',
          title: 'Success',
        });
      },
      onFailed: () => {
        addNotification({
          intent: 'danger',
          content: 'Something went wrong, please try again.',
          title: 'Fail',
        });
      },
    });

  const [isShowVerificationEmailBanner, setIsShowVerificationEmailBanner] =
    useState(true);

  return isShowVerificationEmailBanner && isAuthenticated ? (
    <Banner
      sx={{
        backgroundColor: isLocked ? 'primary' : 'dodgerBlueLight75',
      }}
      icon={null}
      content={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography.Text fontWeight="bold">
              Verify your email now
            </Typography.Text>

            <Typography.Text>
              Haven&apos;t verified your email yet? Click resend to have your
              account activated.
            </Typography.Text>
          </Box>

          <Box>
            <Button
              text="Resend"
              intent="primary"
              onClick={() => {
                track(TRACT_ATS_RESEND_BUTTON_CLICK);
                if (!isNil(email)) {
                  resendVerififcationEmail({ email });
                }
              }}
              loading={isResendingVerificationEmail}
              sx={{ mr: 'small' }}
            />

            <Button.Icon
              icon="cancel"
              intent="text"
              onClick={() => setIsShowVerificationEmailBanner(false)}
            />
          </Box>
        </Box>
      }
    />
  ) : null;
};

export default EmailVerificationBanner;
