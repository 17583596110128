import { useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Box } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import AuthStatus from '@shared/AuthStatus';
import LocalesDropdown from '@shared/LocalesDropdown';
import { Logo } from '@shared/Logo';

import MenuItemLevel1 from './MenuItemLevel1';
import { mapMenuData } from './helpers';
import { MappedMenuData } from './types';

import rawMenuData from './data.json';

interface NavWrapperProps {
  centerItem?: boolean;
}

const NavWrapper = styled.div<NavWrapperProps>`
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  padding: 0px 140px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centerItem ? 'center' : 'flex-start')};
  height: 86px;
`;

const LinkWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const NavMenu = () => {
  const [selectedLevel1, setSelectedLevel1] = useState<string | null>(null);

  const { query } = useRouter();
  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: query?.mode,
      }),
    [query?.mode]
  );

  const onClose = () => {
    setSelectedLevel1(null);
  };

  const onOpen = (item: string) => {
    setSelectedLevel1(item);
  };

  const level1Items: MappedMenuData = mapMenuData(rawMenuData);

  return (
    <>
      <NavWrapper>
        <Link href={normalizeHref('/')} passHref legacyBehavior>
          <LinkWrapper>
            <Logo id="eh-logo" />
          </LinkWrapper>
        </Link>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {level1Items.map(item => (
              <MenuItemLevel1
                key={item.id}
                selectedLevel1={selectedLevel1}
                item={item}
                onClose={onClose}
                onOpen={onOpen}
              />
            ))}
          </Box>
          <Box
            sx={{ margin: '0 0 0 auto', display: 'flex', alignItems: 'center' }}
          >
            <LocalesDropdown />

            <AuthStatus />
          </Box>
        </Box>
      </NavWrapper>
    </>
  );
};

export default NavMenu;
