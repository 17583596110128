import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { jobsPalette } from '@hero-design/colors';
import { getTheme, systemPalette, themeScale } from '@hero-design/react';

const swagSystemPalette = {
  ...systemPalette,
  neutralLightBackground: jobsPalette.hitPinkLight80,
  primary: jobsPalette.apple,
  lightPrimary: jobsPalette.appleLight10,
  hoverPrimary: '#B67C22',
  primaryBackground: jobsPalette.hitPinkLight80,
  primaryLightBackground: jobsPalette.hitPinkLight90,
  primaryDarkBackground: jobsPalette.apple,
};

const swagScale = {
  ...themeScale,
};
const swagTheme = getTheme(swagScale, swagSystemPalette);

// eslint-disable-next-line immutable/no-mutation
swagTheme.__hd__.button.colors.background = {
  ...swagTheme.__hd__.button.colors.background,
  primary: jobsPalette.maasstrichtBlue,
  hoverPrimary: jobsPalette.maasstrichtBlueLight30,
  activePrimary: jobsPalette.black,
  loadingPrimary: jobsPalette.black,
  disabledBackground: jobsPalette.maasstrichtBlueLight90,
};

const GlobalThemeProvider = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={swagTheme}>{children}</ThemeProvider>
);

export default GlobalThemeProvider;
