export const getLoginRecaptchaEnabled = () =>
  process.env.NEXT_PUBLIC_REACT_APP_LOGIN_RECAPTCHA_ENABLED === 'true';

export const getReCaptchaV2SiteKey = () =>
  process.env.NEXT_PUBLIC_REACT_APP_RECAPTCHA_V2_SITE_KEY;

export const getReCaptchaCheckboxSiteKey = () =>
  process.env.NEXT_PUBLIC_RECAPTCHA_CHECKBOX_SITE_KEY;
export const getInvisibleReCaptchaV2SiteKey = () =>
  process.env.NEXT_PUBLIC_REACT_APP_INVISIBLE_RECAPTCHA_V2_SITE_KEY;

export const getReCaptchaV3SiteKey = () =>
  process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITEKEY;

export const getReCaptchaV3SecretKey = () =>
  process.env.RECAPTCHA_V3_SECRET_KEY;

export const getMainAppHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MAIN_APP_HOST;

export const getSmartMatchRecaptchaSiteKey = () =>
  process.env.NEXT_PUBLIC_RECAPTCHA_V2_SMART_MATCH_SITE_KEY;

export const getDirectMainAppHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MAIN_APP_DIRECT_HOST;

export const getInternalMainAppHost = () =>
  process.env.INTERNAL_MAIN_APP_API_HOST;

export const getAtsServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_ATS_SERVICE_API_HOST;

export const getAtsServiceDirectApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_ATS_SERVICE_DIRECT_API_HOST;

export const getInternalAtsServiceApiHost = () =>
  process.env.INTERNAL_ATS_SERVICE_API_HOST;

export const getFileServiceApiHost = () =>
  process.env.NEXT_PUBLIC_FILE_SERVICE_API_HOST;

export const getFileServiceDirectApiHost = () =>
  process.env.NEXT_PUBLIC_FILE_SERVICE_DIRECT_API_HOST;

export const getFormServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_FORM_SERVICE_API_HOST;

export const getFormDirectServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_FORM_SERVICE_DIRECT_API_HOST;

export const getInternalFormServiceApiHost = () =>
  process.env.INTERNAL_FORM_SERVICE_API_HOST;

export const getUrlTrackingServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_URL_TRACKING_SERVICE_API_HOST;

export const getUrlTrackingServiceDirectApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_URL_TRACKING_SERVICE_DIRECT_API_HOST;

export const getInspectletId = () => process.env.NEXT_PUBLIC_INSPECTLET_ID;

export const getMobileAppDynamicLink = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MOBILE_APP_DYNAMIC_LINK;

export const getMobileAppLink = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MOBILE_APP_LINK;

export const getAppcuesAccountId = () =>
  process.env.NEXT_PUBLIC_APPCUES_ACCOUNT_ID;

export const getWebtrendsOptimizeAccountId = () =>
  process.env.NEXT_PUBLIC_WEBTRENDS_OPTIMIZE_ACCOUNT_ID;

export const getMazeAccountId = () => process.env.NEXT_PUBLIC_MAZE_ACCOUNT_ID;

export const getRateLimitMax = () =>
  parseInt(process.env.NEXT_PUBLIC_RATE_LIMIT_MAX || '', 10);

export const getRateLimitInterval = () =>
  parseInt(process.env.NEXT_PUBLIC_RATE_LIMIT_INTERVAL || '', 10);

export const getBunnyCDNHostName = () =>
  process.env.NEXT_PUBLIC_BUNNY_CDN_HOST_NAME;

export const getGoogleTagManagerId = () => process.env.NEXT_PUBLIC_GTM_ID;

export const getGoogleAPIKey = () =>
  process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_API_KEY;

export const getAbstractAPISecretKey = () => process.env.ABSTRACT_API_KEY;

export const getMarketplaceServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MARKETPLACE_SERVICE_API_HOST;

export const getMarketplaceDirectServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MARKETPLACE_SERVICE_DIRECT_API_HOST;

export const getSuperfundLookupServiceHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_SUPERFUND_LOOKUP_SERVICE_API;

export const getNotificationServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_NOTIFICATION_GATEWAY_SERVICE_API_HOST;

export const getNotificationServiceDirectApiHost = () =>
  process.env
    .NEXT_PUBLIC_REACT_APP_NOTIFICATION_GATEWAY_SERVICE_DIRECT_API_HOST;

export const getSalaryGuideHost = () =>
  process.env.NEXT_PUBLIC_SALARY_GUIDE_HOST;

export const getSalaryGuideDirectApiHost = () =>
  process.env.NEXT_PUBLIC_SALARY_GUIDE_DIRECT_HOST;

export const getSalaryGuideReCaptchaV2Sitekey = () =>
  process.env.NEXT_PUBLIC_SALARY_GUIDE_RECAPTCHA_V3_SITEKEY || '';

export const getIndustryPagesEnabled = () =>
  process.env.NEXT_PUBLIC_REACT_APP_INDUSTRY_PAGES_ENABLED === 'true';

export const getProfileMilestonesEnabled = () =>
  process.env.NEXT_PUBLIC_REACT_APP_PROFILE_MILESTONES_ENABLED === 'true';

export const getBusinessSearchEnabled = () =>
  process.env.BUSINESS_SEARCH_ENABLED === 'true';

export const getHeroFoundationSignUpEnabled = () =>
  process.env.NEXT_PUBLIC_REACT_APP_HERO_FOUNDATION_SIGN_UP_ENABLED === 'true';

export const getHeroFoundationCompanyUUID = () =>
  process.env.NEXT_PUBLIC_REACT_APP_HERO_FOUNDATION_COMPANY_UUID;

export const getLocationsListHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_LOCATIONS_LIST_HOST;

export const getCareerPageHost = () => process.env.NEXT_PUBLIC_CAREER_PAGE_HOST;
