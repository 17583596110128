import React, { ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Box, SxObject } from '@hero-design/react';

import { getInspectletId } from '@packages/eh-utils/browserEnv';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import AppBanner from '@shared/AppBanner';
import NavBar from '@shared/NavBar';
import NavMenu from '@shared/NavMenu';
import SiteFooter from '@shared/SiteFooter';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useWindowSize from '@shared/hooks/useWindowSize';
import { getMobileDetector } from '@shared/utils/getMobileDetector';
import { getSwagNavBarItems } from '@shared/utils/getSwagNavBarItems';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

import AppDownloadBanner from 'src/modules/CareersPage/components/LandingPage/AppDownloadBanner';
import useGetUnreadNotificationCounter from 'src/modules/notifications/hooks/useGetUnreadNotificationCounter';

import useInspectletTracking from '../trackTools/inspectlet/useInspectletTracking';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ChildrenWrapper = styled.div`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.lg} {
    width: 100%;
  }
`;

type PageLayoutProps = {
  children: ReactNode;
  pageProps?: {
    hideNavBar?: boolean;
    hideNavBarForSmallScreen?: boolean;
    hideFooter?: boolean;
  };
};

const HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER: string[] = [
  '/setup_profile',
  '/smartmatch_introduction',
  '/user/setup_profile',
  '/user/setup_job_preferences',
  '/user/setup_success',
];

const PageLayout = ({ children, pageProps = {} }: PageLayoutProps) => {
  const { status } = useSession();
  const { track } = useMixpanelTracking();
  const { isSmallScreen } = useWindowSize();
  const router = useRouter();

  const { permissionsData } = useFetchPermissions();
  const { data: unreadNotificationData } = useGetUnreadNotificationCounter({});

  const isAuthenticated = status === 'authenticated';

  const navBarItems = getSwagNavBarItems({
    permissionsData,
    isAuthenticated,
    track,
    notificationsCount: unreadNotificationData,
  });

  const navMenuRefactoringEnabled =
    permissionsData?.data.nav_menu_refactoring_enabled ?? false;

  useInspectletTracking(getInspectletId());

  const isMobile = getMobileDetector()?.isMobile ?? false;

  const hideNavBar = useMemo(
    () =>
      pageProps.hideNavBar ||
      (isSmallScreen && pageProps.hideNavBarForSmallScreen),
    [isSmallScreen, pageProps.hideNavBar, pageProps.hideNavBarForSmallScreen]
  );

  const showAppBanner = useMemo(
    () =>
      isMobile && !HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER.includes(router.pathname),
    [isMobile, router.pathname]
  );

  return (
    <Box style={{ flex: 1 }}>
      <Box>{showAppBanner && <AppDownloadBanner isMobile />}</Box>

      <PageContainer>
        {!hideNavBar && (
          <Box sx={{ zIndex: 100 }}>
            {navMenuRefactoringEnabled ? (
              <NavMenu />
            ) : (
              <NavBar navBarItems={navBarItems} />
            )}
          </Box>
        )}

        <AppBanner />
        <StyledDiv>{children}</StyledDiv>

        {!pageProps.hideFooter && (
          <Box data-test-id="footer" sx={{ zIndex: 1 }}>
            <SiteFooter />
          </Box>
        )}
      </PageContainer>
    </Box>
  );
};

export const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <ChildrenWrapper>
    <Box p="large" mt="xxxlarge">
      {children}
    </Box>
  </ChildrenWrapper>
);

export const JobListWrapper = ({
  children,
  containerSx,
}: {
  children: ReactNode;
  containerSx?: SxObject;
}) => (
  <Box
    bgColor="grey-light-85"
    sx={{
      minHeight: '80vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      ...containerSx,
    }}
  >
    <ChildrenWrapper>
      <Box p="large">{children}</Box>
    </ChildrenWrapper>
  </Box>
);

export default PageLayout;
