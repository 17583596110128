import { toast } from 'react-toastify';

import { Notification } from '@hero-design/react';

type AddNotificationProps = {
  intent: 'success' | 'info' | 'warning' | 'danger' | 'error';
  content: string;
  title: string;
};

export const addNotification = ({
  intent,
  content,
  title,
}: AddNotificationProps) => {
  toast(<Notification intent={intent} content={content} title={title} />);
};
