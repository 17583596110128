import React from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Button,
  Divider,
  LinkButtonProps,
  Typography,
} from '@hero-design/react';

import { Logo } from '@shared/Logo';
import useWindowSize from '@shared/hooks/useWindowSize';

const FooterLink = ({ text, href }: Pick<LinkButtonProps, 'text' | 'href'>) => (
  <Button.Link
    href={href}
    text={text}
    size="small"
    rel="noopener noreferrer"
    target="_blank"
    sx={{ color: 'inherit' }}
  />
);

const SiteFooter = () => {
  const { isSmallScreen } = useWindowSize();
  const theme = useTheme();

  return (
    <Box
      bgColor="black"
      p="medium"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box style={{ width: '90%' }}>
        <Button.Link
          href="https://swagapp.com"
          target="_blank"
          text={<Logo color="white" width={75} id="swag-white-logo" />}
        />
        <Box style={{ width: '100%' }}>
          <Divider
            marginY="small"
            sx={{ borderBottomColor: 'defaultLightBackground' }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isSmallScreen ? 'column-reverse' : 'row',
          }}
        >
          <Typography.Text intent="white" fontSize={10}>
            2024 © Swag. All rights reserved
          </Typography.Text>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              color: 'lightText',
              gap: isSmallScreen ? theme.space.small : theme.space.large,
            }}
          >
            <FooterLink
              href="https://employmenthero.com/legals/"
              text="Legal centre"
            />
            <FooterLink
              href="https://jobs.swagapp.com/cookie_policy"
              text="Cookie policy"
            />
            <FooterLink
              href="https://employmenthero.com/legals/privacy-policy/"
              text="Privacy policy"
            />
            <FooterLink
              href="https://employmenthero.com/legals/terms-conditions/"
              text="Terms"
            />
            <FooterLink
              href="https://employmenthero.com/legals/swag-jobs-product-statement/"
              text="Swag Jobs Product Statement"
            />
            {isSmallScreen && (
              <Divider
                marginY="small"
                sx={{ borderBottomColor: 'defaultLightBackground' }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteFooter;
