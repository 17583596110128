import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isNaN from 'lodash/fp/isNaN';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';

import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';

import { stringify } from 'qs';

import { getFormServiceApiHost } from '@packages/eh-utils/browserEnv';
import { tryJSON } from '@shared/utils';

import type { ApiConfigs, Method } from './types';

type FetcherCreatorOptions<TResponse, TParams, TError> = Pick<
  ApiConfigs<TResponse, TParams, TError>,
  'endpoint' | 'method' | 'headers' | 'formatBody' | 'formatEndpoint'
> & {
  sessionData: Session | null;
  sessionStatus: 'loading' | 'authenticated' | 'unauthenticated';
};

const getFormAuthHeader = (endpoint: RequestInfo) => {
  const formServiceApiHost = getFormServiceApiHost();

  if (
    formServiceApiHost != null &&
    endpoint.toString().includes(formServiceApiHost)
  ) {
    return process.env.NEXT_PUBLIC_FORM_SECRET_TOKEN;
  }

  return undefined;
};

const getLocalAuthHeader = (sessionData: Session | null) => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.NEXT_PUBLIC_SETUP_LOCAL_EH_AUTH_HEADER === 'true'
  ) {
    return JSON.stringify({
      member: {
        user_id: sessionData?.user.id || '',
        email: sessionData?.user.email || '',
      },
    });
  }

  return undefined;
};

const formatHeaders = (headers: Record<string, unknown>) =>
  Object.keys(headers).reduce((result, key) => {
    if (headers[key] == null) return result;

    return {
      ...result,
      [key]: headers[key],
    };
  }, {});

export const generateQueryEndpoint = <TParams>(
  endpoint: RequestInfo,
  params: TParams
) => {
  const normalizedParamsFunction = flow(omitBy(isNil), omitBy(isNaN));
  const normalizedParams = normalizedParamsFunction(
    params as unknown as object
  );

  const composedParams = isEmpty(normalizedParams)
    ? ''
    : `?${stringify(normalizedParams, {
        encoder: encodeURIComponent,
        arrayFormat: 'brackets',
      }).toString()}`;

  return `${endpoint}${composedParams}`;
};

export const defaultFormatEndpoint = <TParams>({
  endpoint,
  params,
  method,
}: {
  endpoint: RequestInfo;
  params?: TParams;
  method?: Method;
}) => {
  if (method === 'GET' && params != null) {
    return generateQueryEndpoint<TParams>(endpoint, params);
  }

  return endpoint;
};

const apiCalls = new Map<
  string,
  | { status: 'pending'; promise: Promise<Response> }
  | { status: 'resolved'; promise: null }
>();

export const dedupeEnhancer =
  <TParams, TResponse, TError>(opts: {
    getKey: (params?: TParams) => RequestInfo;
    onStart: () => void;
    onCompleted: (response: TResponse) => void;
    onFailed: (response: TError) => void;
  }) =>
  (fetcher: (params?: TParams) => Promise<Response>) =>
  async (params?: TParams): Promise<TResponse> => {
    const apiCallKey = JSON.stringify({ key: opts.getKey(params), params });
    // eslint-disable-next-line immutable/no-let
    let apiCall = apiCalls.get(apiCallKey);

    if (apiCall == null || (apiCall && apiCall.status === 'resolved')) {
      opts.onStart();
      const promise = fetcher(params);

      apiCall = {
        status: 'pending',
        promise,
      };

      apiCalls.set(apiCallKey, apiCall);
    }

    if (apiCall.status === 'pending') {
      return apiCall.promise.then(async (response: Response) => {
        const clonedResponse = response.clone();

        const clonedResponseData = tryJSON(await clonedResponse.text());

        if (!clonedResponse.ok) {
          opts.onFailed(clonedResponseData);
          apiCalls.set(apiCallKey, { status: 'resolved', promise: null });
        } else {
          opts.onCompleted(clonedResponseData);
          apiCalls.set(apiCallKey, { status: 'resolved', promise: null });
        }

        return clonedResponseData;
      });
    }

    return apiCall as Promise<TResponse>;
  };

export const swrEnhancer =
  <TParams, TResponse>({
    onCompleted,
    onFailed,
  }: {
    onCompleted: (response: Response) => void;
    onFailed: (err: Response) => void;
  }) =>
  (fetcher: (params?: TParams) => Promise<Response>) =>
  (params?: TParams): Promise<TResponse> =>
    fetcher(params).then(async res => {
      if (!res.ok) {
        const error = Error('An error occurred while fetching the data.');
        const customError = {
          ...error,
          info: await res.clone().json(),
          status: res.status,
        };

        onFailed(res);
        throw customError;
      } else {
        onCompleted(res);
      }

      return res.json() as Promise<TResponse>;
    });

// Buffertime in millisecond
const BUFFER_TIME = 120 * 1000;

export const fetcherCreator =
  <TResponse, TParams, TError>({
    endpoint,
    method = 'GET',
    headers,
    sessionData,
    sessionStatus,
    formatBody = JSON.stringify,
    formatEndpoint = defaultFormatEndpoint,
  }: FetcherCreatorOptions<TResponse, TParams, TError>) =>
  async (params?: TParams): Promise<Response> => {
    const isTokenExpired =
      sessionData &&
      sessionData.expiredAt &&
      Date.now() + BUFFER_TIME > sessionData.expiredAt;
    const baseEndpoint = formatEndpoint({ endpoint, params, method });
    const composedSession =
      isTokenExpired || sessionStatus === 'loading'
        ? await getSession()
        : sessionData;

    return fetch(baseEndpoint, {
      method,
      headers: formatHeaders({
        'Content-Type': 'application/json',
        ...headers,
        'Jwt-Token': composedSession?.jwt || headers?.['Jwt-Token'],
        'Eh-Auth': getLocalAuthHeader(composedSession),
        'Eh-Auth-Form': getFormAuthHeader(endpoint),
      }),
      body: method !== 'GET' ? formatBody(params) : undefined,
    }) as unknown as Promise<Response>;
  };
