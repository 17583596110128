import { useCallback, useMemo, useReducer } from 'react';
import { useSession } from 'next-auth/react';

import {
  dedupeEnhancer,
  defaultFormatEndpoint,
  fetcherCreator,
  swrEnhancer,
} from './helpers';
import type { ApiConfigs, DefaultError } from './types';

type ApiState<TResponse, TError> = {
  currentApi: {
    data: TResponse | null;
    loading: boolean;
    error: TError | null;
  };
};

type ApiAction<TResponse, TError> =
  | {
      type: 'fetch_start';
    }
  | { type: 'fetch_complete'; payload: TResponse }
  | { type: 'fetch_failed'; payload: TError }
  | { type: 'reset'; payload: ApiState<TResponse, TError> };

const initialState = {
  currentApi: { data: null, loading: false, error: null },
};

export type { ApiConfigs };

export const useFetch = <TResponse, TParams, TError = DefaultError>({
  endpoint,
  headers,
  formatEndpoint = defaultFormatEndpoint,
  formatBody = JSON.stringify,
  method = 'GET',
  onCompleted,
  onFailed,
  withSWR = false,
}: ApiConfigs<TResponse, TParams, TError>) => {
  const { data: sessionData, status: sessionStatus } = useSession();

  const reducer = useCallback(
    (
      state: ApiState<TResponse, TError>,
      action: ApiAction<TResponse, TError>
    ): ApiState<TResponse, TError> => {
      switch (action.type) {
        case 'fetch_start': {
          return {
            ...state,
            currentApi: { ...state.currentApi, loading: true },
          };
        }

        case 'fetch_complete': {
          return {
            ...state,
            currentApi: { data: action.payload, loading: false, error: null },
          };
        }

        case 'fetch_failed': {
          return {
            ...state,
            currentApi: {
              ...state.currentApi,
              loading: false,
              error: action.payload,
            },
          };
        }

        case 'reset': {
          return initialState;
        }

        default:
          return state;
      }
    },
    []
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const fetcher = useMemo(() => {
    const baseFetcher = fetcherCreator<TResponse, TParams, TError>({
      endpoint,
      method,
      headers,
      sessionData,
      sessionStatus,
      formatBody,
      formatEndpoint,
    });

    return withSWR
      ? swrEnhancer<TParams, TResponse>({
          onCompleted: async response => {
            const clonedResponse = response.clone();
            const data = await clonedResponse.json();

            onCompleted?.(data);
          },
          onFailed: async response => {
            const clonedResponse = response.clone();
            const error = await clonedResponse.json();

            onFailed?.(error);
          },
        })(baseFetcher)
      : dedupeEnhancer<TParams, TResponse, TError>({
          getKey: params => formatEndpoint({ endpoint, params, method }),
          onStart: () => {
            dispatch({ type: 'fetch_start' });
          },
          onCompleted: async responseData => {
            dispatch({
              type: 'fetch_complete',
              payload: responseData,
            });

            onCompleted?.(responseData);
          },
          onFailed: async responseError => {
            dispatch({ type: 'fetch_failed', payload: responseError });
            onFailed?.(responseError);
          },
        })(baseFetcher);
  }, [
    method,
    endpoint,
    sessionData,
    sessionStatus,
    headers,
    formatBody,
    formatEndpoint,
    withSWR,
    onCompleted,
    onFailed,
  ]);

  const resetter = useCallback(() => {
    dispatch({ type: 'reset', payload: initialState });
  }, []);

  const { data, loading, error } = state.currentApi;

  return {
    fetcher,
    resetter,
    data,
    error,
    loading,
  };
};
