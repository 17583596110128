import kebabCase from 'lodash/fp/kebabCase';

import { MenuData } from './types';

export const mapMenuData = (data?: MenuData | null) => {
  if (!data) {
    return [];
  }

  return data.map(item => ({
    ...item,
    id: kebabCase(item.title),
  }));
};
